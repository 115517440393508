import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
const axios = require('axios');

class App extends Component {
  constructor(){
    super();
    this.state = {
      forma: "",
        formastart: 1,
        dphstart: 0,
        zamestnavatelstart: 0,
        dph: "",
        zamestnavatel: "",
        pocet: "",
        afterPocet: false,
        motorove: 0,
        motorove_count: "",
        banka: 0,
        banka_count: "",
        polozky: 9,
        polozky_count: "",
        vydane: 0,
        vydane_count: "",
        prijate: 0,
        prijate_count: "",
        registracne: 0,
        registracne_count: "",
        priemerne: 0,
        priemerne_count: "",
        finish: 0,
        suma: 0,
        meno: "",
        email: "",
        success: 0,
        phone: "",
        gdpr: 0,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormaChange = this.handleFormaChange.bind(this);
      this.handleSubmitDph = this.handleSubmitDph.bind(this);
      this.handleDphChange = this.handleDphChange.bind(this);
      this.handleZamestnavatelChange = this.handleZamestnavatelChange.bind(this);
      this.handlePocetChange = this.handlePocetChange.bind(this);
      this.handleSubmitPocet = this.handleSubmitPocet.bind(this);

      this.handleMotoroveChange = this.handleMotoroveChange.bind(this);
      this.handleSubmitMotorove = this.handleSubmitMotorove.bind(this);
      this.handleBankaChange = this.handleBankaChange.bind(this);
      this.handleSubmitBanka = this.handleSubmitBanka.bind(this);

      this.handlePolozkyChange = this.handlePolozkyChange.bind(this);
      this.handleSubmitPolozky = this.handleSubmitPolozky.bind(this);

      this.handleVydaneChange = this.handleVydaneChange.bind(this);
      this.handleSubmitVydane = this.handleSubmitVydane.bind(this);

      this.handlePrijateChange = this.handlePrijateChange.bind(this);
      this.handleSubmitPrijate = this.handleSubmitPrijate.bind(this);

      this.handleRegistracneChange = this.handleRegistracneChange.bind(this);
      this.handleSubmitRegistracne = this.handleSubmitRegistracne.bind(this);

      this.handlePriemerneChange = this.handlePriemerneChange.bind(this);
      this.handleSubmitPriemerne = this.handleSubmitPriemerne.bind(this);

      this.handleGdpr = this.handleGdpr.bind(this);
      this.handleEmail = this.handleEmail.bind(this);
      this.handlePhone = this.handlePhone.bind(this);
      this.handleMeno = this.handleMeno.bind(this);
      this.odosli = this.odosli.bind(this);

  }

    handleGdpr = (event) => {
      this.setState({
          gdpr: !this.state.gdpr,
      });
    }
    handleEmail = (event) => {
      this.setState({
          email: event.target.value,
      })
    }
    handlePhone = (event) => {
      this.setState({
          phone: event.target.value,
      })
    }
    handleMeno = (event) => {
      this.setState({
          meno: event.target.value,
      })
    }
    odosli = (event) => {
      event.preventDefault();



      var datas = {
          email: this.state.email,
          meno: this.state.meno,
          phone: this.state.phone,
          forma: this.state.forma,
          dph: this.state.dph,
          zamestnavatel: this.state.zamestnavatel,
          pocet: this.state.pocet,
          banka: this.state.banka_count,
          motorove: this.state.motorove_count,
          polozky: this.state.polozky_count,
          prijate: this.state.prijate_count,
          vydane: this.state.vydane_count,
          registracne: this.state.registracne_count,
          priemerne: this.state.prijate_count,
      }
     datas = JSON.stringify(datas);


      axios.post("https://posta.firmaprefirmy.sk/odosli-uctovnictvo", datas).then(function (response) {
         this.setState({
             success: 1,
         })
      }.bind(this));
      this.setState({
          meno: "",
          gdpr: 0,
          email: "",
          phone: "",

      })
    }


  handleSubmit = (event) => {
    event.preventDefault();
      this.setState({
          dphstart: 1,
          formastart: 0,
      })

  }
    handleSubmitDph = (event) => {
    event.preventDefault();

        this.setState({
            zamestnavatelstart: 1,
            dphstart: 0,
        })

    }

    handleSubmitZamestnavatel = (event) => {
    event.preventDefault();
        this.setState({

            zamestnavatelstart: 0,
        })


        if(event.target.value == 2){
            this.setState({
                motorove: 1,
            })
        }

    }

    handlePocetChange = (event) => {
   this.setState({
       pocet: event.target.value,
   })
  }

    handleBankaChange = (event)=>{
    this.setState({
        banka_count: event.target.value,
    })
    }

    handleSubmitBanka = (event) => {
    event.preventDefault();

    this.setState({
        banka_count: document.getElementsByClassName("banka")[0].value,
    });


        if(document.getElementsByClassName("banka")[0].value == "") {
            this.setState({
               banka_count: 0,
            });
        }
            this.setState({
                banka: 2,
                polozky: 1,
            });


    }

    newcount = () => {
     this.setState({

         forma: "",
         formastart: 1,
         dphstart: 0,
         zamestnavatelstart: 0,
         dph: "",
         zamestnavatel: "",
         pocet: "",
         afterPocet: false,
         motorove: 0,
         motorove_count: "",
         banka: 0,
         banka_count: "",
         polozky: 9,
         polozky_count: "",
         vydane: 0,
         vydane_count: "",
         prijate: 0,
         prijate_count: "",
         registracne: 0,
         registracne_count: "",
         priemerne: 0,
         priemerne_count: "",
         finish: 0,
         suma: 0,

     });
    }

    handleMotoroveChange = (event) => {


        this.setState({
            motorove_count: event.target.value
        })
    }




    handleSubmitPocet = (event) => {
    event.preventDefault();



        if(this.state.pocet != ""){
          this.setState({
             motorove: 1,
          });
        }

    }



    handleSubmitMotorove = (event) => {
        event.preventDefault();

        this.setState({
            motorove_count: document.getElementsByClassName("motorove")[0].value
        });

        if(document.getElementsByClassName("motorove")[0].value == "") {
            this.setState({
                motorove_count: 0,
            })
        }
            this.setState({
                banka: 1,
                motorove: 2,
            });


    }

    handleVydaneChange = (event) => {
        this.setState({
            vydane_count: event.target.value,
        })
    }

    handleSubmitVydane = (event) => {
        event.preventDefault();
        this.setState({
            vydane_count: document.getElementsByClassName("vydane")[0].value
        })
        if(document.getElementsByClassName("vydane")[0].value == ""){
           this.setState({
              vydane_count: 0,
           });
        }
        this.setState({
            prijate: 1,
            vydane: 2,
        });

    }


    handleRegistracneChange = (event) => {
        this.setState({
            registracne_count: event.target.value,
        })
    }

    handleSubmitRegistracne = (event) => {
        event.preventDefault();

        this.setState({
            registracne_count: document.getElementsByClassName("registracne")[0].value,
        })

        if(document.getElementsByClassName("registracne")[0].value == ""){
           this.setState({
               registracne_count: 0,
           })
        }

        this.setState({
            priemerne: 1,
            registracne: 2,
        });

    }


    handlePolozkyChange = (event) => {
        this.setState({
            polozky_count: event.target.value,
        })
    }

    handleSubmitPolozky = (event) => {
        event.preventDefault();

        this.setState({
            polozky_count: document.getElementsByClassName("polozky")[0].value,
        })





        if( document.getElementsByClassName("polozky")[0].value == "") {
            this.setState({
               polozky_count: 0,
            });
        }
            this.setState({
                vydane: 1,
                polozky: 2,
            });


    }



    handlePrijateChange = (event) => {
        this.setState({
            prijate_count: event.target.value,
        })
    }

    handleSubmitPrijate = (event) => {
        event.preventDefault();

        this.setState({
            prijate_count: document.getElementsByClassName("prijate")[0].value
        })

        if(document.getElementsByClassName("prijate")[0].value == ""){
           this.setState({
               prijate_count: 0,
           })
        }
        this.setState({
            registracne: 1,
            prijate: 2,
        });

    }

    handlePriemerneChange = (event) => {

        this.setState({
            priemerne_count: event.target.value,
        })
    }


    handleSubmitPriemerne = (event) => {
        event.preventDefault();
        this.setState({
            priemerne_count: document.getElementsByClassName("zjednodusene")[0].value
        })



        if(document.getElementsByClassName("zjednodusene")[0].value == "" ){
            this.setState({
                priemerne_count: 0,
            })
        }

        this.setState({
            finish: 1,
            priemerne: 2,
        });

    }

    handleFormaChange = (event) => {
        this.setState({
            forma: event.target.value,
            dphstart: 1,
            formastart: 0,
        })
    }
    handleDphChange = (event) => {

        this.setState({
            dph: event.target.value,
            zamestnavatelstart: 1,
            dphstart: 0,
        })
    }


    handleZamestnavatelChange = (event) => {
        this.setState({
            zamestnavatel: event.target.value,
            zamestnavatelstart: 0,
        })


        if(event.target.value == 2){
          this.setState({
              motorove: 1,
          })
        }
    }

    sumaCount = () =>{
    var suma = 0;

      if(this.state.forma == 1){
        if(this.state.dph == 1){
          suma = suma + 30;
          if(this.state.zamestnavatel == 1){
            suma = suma + ((this.state.pocet) * 7);
          }
          if(this.state.motorove_count >0){
            suma = suma + 10 + (this.state.motorove_count * 5);
          }

          //bankove ucty

            if(this.state.banka_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.banka_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.banka_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.banka_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.banka_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.banka_count <= 200){
                suma = suma + 60;
            }

            //vydane faktury

            if(this.state.vydane_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.vydane_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.vydane_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.vydane_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.vydane_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.vydane_count <= 200){
                suma = suma + 60;
            }

            //prijate faktury



            if(this.state.prijate_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.prijate_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.prijate_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.prijate_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.prijate_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.prijate_count <= 200){
                suma = suma + 60;
            }

            // priemerne faktury

            if(this.state.priemerne_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.priemerne_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.priemerne_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.priemerne_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.priemerne_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.priemerne_count <= 200){
                suma = suma + 60;
            }




        }
        else if(this.state.dph == 2){

            if(this.state.zamestnavatel == 1){
                suma = suma + ((this.state.pocet) * 7);
            }
            if(this.state.motorove_count >0){
                suma = suma + 10 + (this.state.motorove_count * 5);
            }
            //bankove ucty
            if(this.state.banka_count <= 10){
              suma = suma + 10;
            }
            else if(this.state.banka_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.banka_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.banka_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.banka_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.banka_count <= 200){
                suma = suma + 60;
            }

            //vydane faktury

            if(this.state.vydane_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.vydane_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.vydane_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.vydane_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.vydane_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.vydane_count <= 200){
                suma = suma + 60;
            }

            //prijate faktury



            if(this.state.prijate_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.prijate_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.prijate_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.prijate_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.prijate_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.prijate_count <= 200){
                suma = suma + 60;
            }

            // priemerne faktury

            if(this.state.priemerne_count <= 10){
                suma = suma + 10;
            }
            else if(this.state.priemerne_count <= 30){
                suma = suma + 12;
            }
            else if(this.state.priemerne_count <= 60){
                suma = suma + 25;
            }
            else if(this.state.priemerne_count <= 100){
                suma = suma + 40;
            }
            else if(this.state.priemerne_count <= 150){
                suma = suma + 50;
            }
            else if(this.state.priemerne_count <= 200){
                suma = suma + 60;
            }


        }

      }
      else if(this.state.forma == 2){

          if(this.state.dph == 1){

            suma = suma + 30;

              if(this.state.zamestnavatel == 1){
                  suma = suma + ((this.state.pocet) * 7);
              }
              if(this.state.motorove_count >0){
                  suma = suma + 10 + (this.state.motorove_count * 5);
              }




              //bankove ucty
              if(this.state.banka_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.banka_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.banka_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.banka_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.banka_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.banka_count <= 200){
                  suma = suma + 120;
              }

              //vydane faktury

              if(this.state.vydane_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.vydane_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.vydane_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.vydane_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.vydane_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.vydane_count <= 200){
                  suma = suma + 120;
              }

              //prijate faktury



              if(this.state.prijate_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.prijate_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.prijate_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.prijate_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.prijate_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.prijate_count <= 200){
                  suma = suma + 120;
              }

              // priemerne faktury

              if(this.state.priemerne_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.priemerne_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.priemerne_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.priemerne_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.priemerne_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.priemerne_count <= 200){
                  suma = suma + 120;
              }

          }
          else if(this.state.dph == 2){
              if(this.state.zamestnavatel == 1){
                  suma = suma + ((this.state.pocet) * 7);
              }
              if(this.state.motorove_count >0){
                  suma = suma + 10 + (this.state.motorove_count * 5);
              }


              //bankove ucty
              if(this.state.banka_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.banka_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.banka_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.banka_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.banka_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.banka_count <= 200){
                  suma = suma + 120;
              }

              //vydane faktury

              if(this.state.vydane_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.vydane_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.vydane_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.vydane_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.vydane_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.vydane_count <= 200){
                  suma = suma + 120;
              }

              //prijate faktury



              if(this.state.prijate_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.prijate_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.prijate_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.prijate_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.prijate_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.prijate_count <= 200){
                  suma = suma + 120;
              }

              // priemerne faktury

              if(this.state.priemerne_count <= 10){
                  suma = suma + 20;
              }
              else if(this.state.priemerne_count <= 30){
                  suma = suma + 24;
              }
              else if(this.state.priemerne_count <= 60){
                  suma = suma + 50;
              }
              else if(this.state.priemerne_count <= 100){
                  suma = suma + 80;
              }
              else if(this.state.priemerne_count <= 150){
                  suma = suma + 100;
              }
              else if(this.state.priemerne_count <= 200){
                  suma = suma + 120;
              }

          }

      }

      return suma;


    }

    goBack(x){


      if(x==1){

        this.setState({
           formastart: 1,
        });

      }
       else if(x==2){
          this.setState({
              dphstart: 1,
          });

        }
      else if(x==3){
          this.setState({
              zamestnavatelstart: 1,
          });

      }
      else if(x==4){
        if(this.state.zamestnavatel == 1) {
            this.setState({
                // zamestnavatel: 1,
                motorove: 0,
            });
        }
        else{
            if(this.state.zamestnavatel == 2) {
                this.setState({
                    // zamestnavatel: 1,
                    zamestnavatelstart: 1,
                });
            }
        }

      }

      else if(x==5){
          this.setState({
              motorove: 1,
          });

      }
      else if(x==6){

          this.setState({
              banka: 1,
          });

      }
      else if(x==7){
          this.setState({
              polozky: 1,
          });

      }
      else if(x==8){
          this.setState({
              vydane: 1,
          });

      }
      else if(x==9){
          this.setState({
              prijate: 1,
          });

      }
      else if(x==10){
          this.setState({
              registracne: 1,
          });

      }
      else if(x==11){
          this.setState({
              priemerne: 1,
          });

      }
    }



    render() {
      if (this.state.formastart == 1) {
          return (
              <div className="App">
                  <header className="App-header">
                      <h2>Vaša forma podnikania</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmit}>
                          <input type="radio" value={1} name="forma" checked={this.state.forma == 1} onChange={this.handleFormaChange}/> živnostník (Fyzická osoba, SZČO)<br/>
                          <input type="radio" value={2} name="forma" checked={this.state.forma == 2} onChange={this.handleFormaChange}/> s.r.o., občianske združenie.... (právnická osoba)
                          {this.state.forma != "" ?
                          <input type="submit" value="Ďalej" />
                              : null }
                      </form>

                  </header>

              </div>
          );
      }
      else if(this.state.dphstart == 1){
          return (
              <div className="App">
                  <header className="App-header">
                      <h2>Platca DPH?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitDph}>
                          <input type="radio" value={1} name="dph" checked={this.state.dph == 1} onChange={this.handleDphChange}/> Áno<br/>
                          <input type="radio" value={2} name="dph" checked={this.state.dph == 2} onChange={this.handleDphChange}/> Nie
                          {this.state.dph != "" ?
                              <input type="submit" value="Ďalej" />
                              : null }
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(1)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }
      else if(this.state.zamestnavatelstart == 1){
          return (
              <div className="App">
                  <header className="App-header">
                      <h2>Zamestnávateľ?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitZamestnavatel}>
                          <input type="radio" value={1} checked={this.state.zamestnavatel == 1} name="zamestnavatel" onChange={this.handleZamestnavatelChange}/> Áno<br/>
                          <input type="radio" value={2} checked={this.state.zamestnavatel == 2} name="zamestnavatel" onChange={this.handleZamestnavatelChange}/> Nie
                          {this.state.zamestnavatel != "" ?
                              <input type="submit" value="Ďalej" />
                              : null }
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(2)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }
      else if(this.state.zamestnavatel == 1 && (this.state.pocet == "" || this.state.motorove == 0)){
          return (
              <div className="App">
                  <header className="App-header">
                      <h2>Uveďte počet zamestnancov – všetkých, tzn. na trvalý pracovný
                          pomer, dohody</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitPocet}>
                         <input type="number" placeholder="Zadajte počet (napr. 1)" value={this.state.pocet}  onChange={this.handlePocetChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(3)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }
      else if(this.state.motorove == 1){
        return(
            <div className="App">
                <header className="App-header">
                    <h2>Počet motorových vozidiel v podnikaní?</h2>
                    <p class="odpoved">Označte odpoveď</p>
                    <form onSubmit={this.handleSubmitMotorove}>
                        <input className="motorove" type="number" placeholder="Zadajte počet (napr. 1)" name="motorove_count" value={this.state.motorove_count}  onChange={this.handleMotoroveChange} />
                        <input type="submit" value="Ďalej" />
                    </form>

                    <a className={"back"} onClick={()=>this.goBack(4)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                </header>

            </div>
        );
      }
      else if(this.state.banka == 1){
          return(
              <div className="App">
                  <header className="App-header">
                      <h2>Počet bankových účtov?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitBanka}>
                          <input type="number" className="banka" placeholder="Zadajte počet (napr. 1)"  value={this.state.banka_count}  onChange={this.handleBankaChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(5)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }

      else if(this.state.polozky == 1){
          return(
              <div className="App">
                  <header className="App-header">
                      <h2>Priemerný počet položiek za mesiac na všetkých výpisoch z účtu?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitPolozky}>
                          <input className="polozky" type="number" placeholder="Zadajte počet (napr. 1)" value={this.state.polozky_count}  onChange={this.handlePolozkyChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(6)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }
      else if(this.state.vydane == 1){
          return(
              <div className="App">
                  <header className="App-header">
                      <h2>Priemerný počet vydaných (odberateľských) faktúr za mesiac?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitVydane}>
                          <input type="number" className="vydane" placeholder="Zadajte počet (napr. 1)" value={this.state.vydane_count}  onChange={this.handleVydaneChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(7)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }

      else if(this.state.prijate == 1){
          return(
              <div className="App">
                  <header className="App-header">
                      <h2>Priemerný počet prijatých (dodávateľských) faktúr za mesiac?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitPrijate}>
                          <input type="number" className="prijate" placeholder="Zadajte počet (napr. 1)" value={this.state.prijate_count}  onChange={this.handlePrijateChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(8)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }
      else if(this.state.registracne == 1){
          return(
              <div className="App">
                  <header className="App-header">
                      <h2>Počet registračných pokladní (VRP, e-kasa)?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitRegistracne}>
                          <input type="number" className="registracne" placeholder="Zadajte počet (napr. 1)" value={this.state.registracne_count}  onChange={this.handleRegistracneChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(9)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }

      else if(this.state.priemerne == 1){
          return(
              <div className="App">
                  <header className="App-header">
                      <h2>Priemerný počet zjednodušených faktúr (nákup v hotovosti)?</h2>
                      <p class="odpoved">Označte odpoveď</p>
                      <form onSubmit={this.handleSubmitPriemerne}>
                          <input className="zjednodusene" type="number" placeholder="Zadajte počet (napr. 1)" value={this.state.priemerne_count}  onChange={this.handlePriemerneChange} />
                          <input type="submit" value="Ďalej" />
                      </form>

                      <a className={"back"} onClick={()=>this.goBack(10)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>

                  </header>

              </div>
          );
      }
      else if(this.state.finish == 1){
        return(
            <div className="App">
                <header className="App-header">
                    <h2 className="nadpis"><span className="blacked">Odhadovaná cena</span><br/><span className="blueize"> {this.sumaCount()}€ / mesiac</span><br/></h2>
                    {/*<a className="btn" onClick={this.newcount}>Znovu</a>*/}
                    <p className="orientacna">Cena je len orientačná a bude upresnená na základe skutočných počtov.</p>

                    {this.state.success == 0 ?
                        <div>
                            <h4 className="kontakt">V prípade záujmu vyplnte nižšie uvedený formulár.</h4>

                        <form className="kontakt" onSubmit={this.odosli}>
                            <input type="text" value={this.state.meno} onChange={this.handleMeno} placeholder="Meno"/>
                            <input type="email" value={this.state.email} onChange={this.handleEmail}
                                   placeholder="E-Mail"/>
                            <input type="text" value={this.state.phone} onChange={this.handlePhone}
                                   placeholder="Tel. číslo"/>
                            <input type="checkbox" value={this.state.gdpr} onChange={this.handleGdpr}
                                   checked={this.state.gdpr == 1}/> Súhlasím so spracovaním osobných údajov v súlade s
                            nariadením GDPR o ochrane osobných údajov (<a target="_blank"
                                                                          href="https://www.firmaprefirmy.sk/ochrana-sukromia"> Viac
                            informácií.</a>)

                            {this.state.gdpr == 1 && this.state.meno != "" && this.state.email != "" && this.state.phone != "" ?
                                <input type='submit' value='Odoslať'/>
                                : <input className="disabled" type='submit' value='Odoslať' disabled/>
                            }
                        </form>
                        </div>
                    : <div>
                            <h6 className="nadpis">Ďakujeme za kontaktovanie. Čoskoro sa Vám ozveme.</h6>
                        </div>}

                    <a className={"back"} onClick={()=>this.goBack(11)}><i className={"fas fa-chevron-left"}></i>Krok späť</a>



                </header>

            </div>
        );
      }

  }

}

export default App;
